import React from 'react';
import './App.css';
import Logo from './LizardNinjaMedia.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="LizardNinjaLogo" src={Logo} alt="LizardNinjaMedia Logo" />
        <p>
          Lizard Ninja Media 2024
        </p>
        <p>Reach me here: <a href='&#109;ailt&#111;&#58;pho&#116;o%&#55;3&#64;l&#105;z&#97;rd&#46;n&#105;nja'>&#112;h&#111;t&#111;s&#64;&#108;izar&#100;&#46;ninj&#97;</a></p>
      </header>
    </div>
  );
}

export default App;
